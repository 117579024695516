import React, { useState, useEffect } from 'react';
import dance from './images/dance.jpg';
import marathon from './images/marathon.jpg';
import magic from './images/magic.png';
import quadball from './images/quadball.jpeg';
import chef from './images/chef.jpeg';
import stem from './images/stem.jpeg';
import app from './images/app.jpeg';
import siblings from './images/siblings.jpeg';
import hackathon from './images/hackathon.jpeg';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

import DistanceCalculator from './DistanceCalculator';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSprings, animated } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import ProjectGallery from './ProjectGallery';
import ChatbotWidget from './ChatbotWidget';

const phrases = [
  { phrase: 'chef', image: chef },
  { phrase: 'marathon runner', image: marathon },
  { phrase: 'dancer', image: dance },
  { phrase: 'tiny tot teacher', image: stem },
  { phrase: 'magician', image: magic },
  { phrase: 'hackathon director', image: hackathon },
  { phrase: 'quadball player', image: quadball },
  { phrase: 'software engineer', image: app },
  { phrase: 'middle child', image: siblings },
];

function Home() {
  const [typingText, setTypingText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [cards, setCards] = useState(phrases);
  const [showAlert, setShowAlert] = useState(false);
  const [alertShownOnce, setAlertShownOnce] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const DRAG_THRESHOLD = 150; // Distance required to change card position

  const [springs, api] = useSprings(cards.length, (index) => ({
    x: -7 * (cards.length - index - 1),
    y: 0,
    scale: index === cards.length - 1 ? 1 : 0.97 ** (cards.length - index - 1),
    rot: index === cards.length - 1 ? 0 : -4 * (cards.length - index - 1),
    from: { x: 0, rot: 0, scale: 1, y: -100 },
  }));

  const bind = useGesture(
    {
      onDrag: ({ args: [index], down, movement: [mx], distance }) => {
        api.start((i) => {
          if (index !== i) {
            return {
              rot: -4 * (cards.length - i - 1),
              scale: 0.97 ** (cards.length - i - 1),
              x: -7 * (cards.length - i - 1),
            };
          }
          const x = down ? mx : 0;
          const rot = mx / 100;
          const scale = down ? 0.997 ** Math.abs(mx) : 1;
          return { x, rot, scale, immediate: down };
        });
      },
      onDragEnd: ({ args: [index], movement: [mx], distance }) => {
        if (distance[0] < DRAG_THRESHOLD) {
          // Snap back to original position if below threshold
          api.start((i) => {
            if (i !== index) return;
            return {
              x: -7 * (cards.length - index - 1),
              y: 0,
              scale: 0.97 ** (cards.length - index - 1),
              rot: -4 * (cards.length - index - 1),
              immediate: false,
            };
          });
          return;
        }

        setAlertShownOnce(true);

        api.start((i) => {
          if (index !== i) return;
          return { y: 1000, rot: 0, scale: 1 };
        });

        setTimeout(() => {
          setCards((prev) => {
            let newCards = [...prev];
            const [swipedCard] = newCards.splice(index, 1);
            newCards = [swipedCard, ...newCards];
            return newCards;
          });

          api.start((i) => {
            if (index === i) {
              return { y: 0, immediate: true };
            }
            return {
              y: 0,
              rot: -4 * (cards.length - i - 1),
              scale: 0.97 ** (cards.length - i - 1),
              x: -7 * (cards.length - i - 1),
            };
          });
        }, 300);
      },
    },
    {
      drag: {
        filterTaps: true,
      },
    }
  );

  useEffect(() => {
    const handleScrollAttempt = () => {
      if (!alertShownOnce) {
        setAlertShownOnce(true);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 4000);
      }
    };

    window.addEventListener('wheel', handleScrollAttempt);

    return () => {
      window.removeEventListener('wheel', handleScrollAttempt);
    };
  }, [alertShownOnce]);



  useEffect(() => {
    let currentText = '';
    let index = 0;
    setIsTyping(true);
    const phrase = cards[cards.length - 1].phrase;

    const typingInterval = setInterval(() => {
      if (index < phrase.length) {
        currentText += phrase[index];
        setTypingText(currentText);
        index++;
      } else {
        clearInterval(typingInterval);
        setIsTyping(false);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [cards]);

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <header className="App-header">
        <button onClick={toggleTheme} className="theme-toggle">
          {isDarkMode ? (
            <FontAwesomeIcon icon={faSun} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faMoon} size="2x" />
          )}
        </button>
        <div
          style={{
            position: 'relative',
            width: '300px',
            height: '400px',
            margin: '0 auto',
            marginBottom: 30,
          }}
        >
          {springs.map(({ x, y, rot, scale }, i) => (
            <animated.div
              key={i}
              style={{
                x,
                y,
                rotateZ: rot,
                scale,
                position: 'absolute',
                width: '100%',
                height: '100%',
                willChange: 'transform',
                touchAction: 'none',
              }}
              {...bind(i)}
            >
              <animated.div
                style={{ backgroundImage: `url(${cards[i].image})`, height: 400 }}
                className="card"
              />
            </animated.div>
          ))}
        </div>

        <h1>Hello 👋! I'm Yuen Ler.</h1>
        <div style={{ marginBottom: 10 }}>
          <span className="intro-text">I am a </span>
          <span className="typing-text">{typingText}</span>
          <span className={`cursor ${isTyping ? '' : 'blink'}`}>|</span>
        </div>
        <div className="mb-4">
          <DistanceCalculator />
        </div>
        <div style={{ fontSize: '1rem', position: 'absolute', bottom: 0 }}>
          <Alert
            show={showAlert}
            className={showAlert ? 'fadeIn' : 'fadeOut'}
            variant="info"
          >
            Try dragging the cards to see my other pictures!
          </Alert>
        </div>
        <button type="button" className="fancy-button" onClick={() => setShowModal(true)}>
          View My Personal Projects 👀
        </button>
      </header>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ProjectGallery />
        </Modal.Body>
        <Modal.Footer>
          <div className="social-icons">
            <a
              href="https://www.linkedin.com/in/yuenler"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link linkedin"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a href="mailto:yuenlerchow@college.harvard.edu" className="social-link email">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </a>
            <a
              href="https://github.com/yuenler"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link github"
            >
              <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
          </div>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ChatbotWidget />
    </div>
  );
}

export default Home;
